import { template as template_aabaf31b55bd4ba7b1a0155905103b10 } from "@ember/template-compiler";
const WelcomeHeader = template_aabaf31b55bd4ba7b1a0155905103b10(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
