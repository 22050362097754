import { template as template_8fe4d35a95f142b48ae7f01a15554fe8 } from "@ember/template-compiler";
const FKLabel = template_8fe4d35a95f142b48ae7f01a15554fe8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
