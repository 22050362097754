import { template as template_f57ffa11084d40959ef9d481633e383e } from "@ember/template-compiler";
const FKText = template_f57ffa11084d40959ef9d481633e383e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
