import { template as template_6c362d3ec37441459fdfb3f9f2afd733 } from "@ember/template-compiler";
const FKControlMenuContainer = template_6c362d3ec37441459fdfb3f9f2afd733(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
